// @ts-nocheck
import { saveAs } from 'file-saver';
import notificationState from '../mobx/notification-state';
import { BASE_URL } from '../constants/globalContants';

const getMimeType = (extension) => {
  const mimeTypes = {
    'pdf': 'application/pdf',
    'zip': 'application/zip',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'webp': 'image/webp',
    'txt': 'text/plain',
    'html': 'text/html',
    'css': 'text/css',
    'js': 'application/javascript',
    'json': 'application/json',
    'xml': 'application/xml',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'mp3': 'audio/mpeg',
    'mp4': 'video/mp4',
    'avi': 'video/x-msvideo',
    'mov': 'video/quicktime',
    'csv': 'text/csv',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'svg': 'image/svg+xml',
    'wav': 'audio/wav',
    'ogg': 'audio/ogg',
    'tar': 'application/x-tar',
    'rar': 'application/vnd.rar',
    '7z': 'application/x-7z-compressed',
    'gz': 'application/gzip'
  };
  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
};

export const DownloadFile = async (pdf_url, token) => {
  try {
    const apiUrl = `${BASE_URL}downloadpdf`;
    const requestData = { pdf_url, token };

    const filename = pdf_url.split("/").pop();
    const extension = filename.split('.').pop();
    const mimeType = getMimeType(extension);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      console.error('Failed to fetch the file:', response.status, response.statusText);
      throw new Error('Failed to download PDF');
    }

    const arrayBuffer = await response.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: mimeType });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      saveAs(blob, filename);
    }
  } catch (error) {
    console.error('Download error:', error);
    notificationState.addToasterError('File download failed');
  }
};
