export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
		access: ["admin", "user"],
	},
	profile: {
		id: 'profile',
		text: 'My Profile',
		path: '/my-profile',
		icon: 'AccountCircle',
		subMenu: null,
		access: ["admin", "user"],
	},
	generatePDf: {
		id: 'generatePDf',
		text: 'Generate Pdf',
		path: '/',
		icon: 'PictureAsPdf',
		subMenu: null,
		access: ["user"],
	},
	subscriptions: {
		id: 'subscriptions',
		text: 'Subscriptions Plans',
		path: '/subscriptions',
		icon: 'Subscriptions',
		access: ["user"],
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users',
		icon: 'People',
		access: ["admin"],
		subMenu: null,
	},
	contactUs: {
		id: 'admin-contact-us-list',
		text: 'Contact Us',
		path: '/admin-contact-us-list',
		icon: 'People',
		access: ["admin"],
		subMenu: null,
	},
	userSubscriptionHistory: {
		id: 'users-subscription-history',
		text: 'My Subscription',
		path: '/user-subscription-history',
		icon: 'History',
		access: ["user"],
		subMenu: null,
	},
	// userPdfHistory: {
	// 	id: 'user-pdf-history',
	// 	text: 'Pdf History',
	// 	path: '/user-pdf-history',
	// 	icon: 'History',
	// 	access: ["user"],
	// 	subMenu: null,
	// },
	keyManagement: {
		id: 'keyManagement',
		text: 'Manage Api Keys',
		path: '/api-key-management',
		icon: 'VpnKey',
		access: ["user"],
		subMenu: null,
	},
	Settings: {
		id: 'Settings',
		text: 'Settings',
		path: '/settings',
		icon: 'Settings',
		access: ["admin"],
		subMenu: null,
	},
	logout: {
		id: 'logout',
		text: 'Logout',
		path: '/auth/login?preaction=logout',
		icon: 'Logout',
		access: ["admin", "user"],
		subMenu: null,
	},

};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	userIndex: {
		id: 'index',
		text: 'Index',
		path: 'index',
		icon: 'index',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
