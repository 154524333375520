//@ts-nocheck
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import rictangle from '../../images/rictangle.png';
import subscriptionState, { ISubsPlan } from '../../../shared/mobx/subscription-state';
import { formatNumberWithDecimal } from '../../../helpers/helpers';
import Spinner from '../../../components/bootstrap/Spinner';
import auth from '../../../shared/mobx/auth-state';
const currencyMapper = {
  "USD": "$"
};

const PackagesSection = () => {
  const [activeTab, setActiveTab] = useState('monthly');
  const [isLoading, setLoading] = useState(true);
  const [monthlyPackages, setMonthlyPackages] = React.useState([]);
  const [yearlyPackages, setYearlyPackages] = React.useState([]);

  const mapPackages = React.useCallback(
    (d: ISubsPlan[], cycle: 'month' | 'year') => {
      return d
        .filter(plan => plan.billing_cycle.interval === cycle)
        .map(plan => ({
          id: plan.id,
          name: plan.name,
          features: [plan.description],
          price: `${currencyMapper[plan.unit_price.currency_code] ?? plan.unit_price.currency_code}${formatNumberWithDecimal(plan.unit_price.amount)}/${plan.billing_cycle.interval}`,
        }));
    },
    []
  );

  React.useEffect(() => {
    // Simulated API call or data fetching
    const fetchData = async () => {
      setLoading(true);
      const packageList_ = await subscriptionState.getSubscriptionPlans(false);
      const newData: ISubsPlan[] = packageList_;

      setMonthlyPackages(mapPackages(newData, 'month'));
      setYearlyPackages(mapPackages(newData, 'year'));

      setLoading(false);
    };

    fetchData();

  }, []);





  return (
    <div className="container" id="pricing">
      <div className="packages-section">
        <h3>Find the perfect plan for your needs</h3>
        <p>
Discover the plan that best fits your requirements and start converting web pages to PDFs with ease. Whether you're a 
casual user or need extensive features, our pricing plans offer flexibility and value to suit your needs. 
Choose the right plan and enjoy seamless document management today!</p>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'monthly' ? 'active' : ''}`}
            onClick={() => setActiveTab('monthly')}
          >
            Monthly
          </button>
          <button
            className={`tab ${activeTab === 'yearly' ? 'active' : ''}`}
            onClick={() => setActiveTab('yearly')}
          >
            Yearly
          </button>
        </div>

        <div className="packages">
          {
            isLoading ? <Spinner size="4rem" color="danger" /> : (
              <div className="row">
                {(activeTab === 'monthly' ? monthlyPackages : yearlyPackages).map((pkg, index) => (
                  <div key={index} className="col-md-4 col-sm-12 col-12">
                    <div className="package">
                      <img src={rictangle} alt={pkg.name} />
                      <h3>{pkg.name}</h3>
                      <p className="price">{pkg.price}</p>
                      <span>Features</span>
                      <ul>
                        {pkg.features.map((feature, featureIndex) => (
                          <span dangerouslySetInnerHTML={{ __html: feature }} key={featureIndex} />
                        ))}
                      </ul>
                      {
                        auth.isUser && (<button
                          className="subscribe-btn"
                          onClick={() =>
                            window.location.href = `/subscriptions`
                          }
                        >
                          Choose Plan
                        </button>)
                      }

                      {
                        auth.isGuest && (<button
                          className="subscribe-btn"
                          onClick={() =>
                            window.location.href = `/auth/sign-up?package_id=${pkg.id} `
                          }
                        >
                          Choose Plan
                        </button>)
                      }

                    </div>
                  </div>
                ))}
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default observer(PackagesSection);
