//@ts-nocheck
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Checks from '../../../components/bootstrap/forms/Checks';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { observer } from 'mobx-react-lite';
import CreateApiKeyModal from './CreateApiKeyModal';
import KeyUsage from "./keyUsage";

import apiKeyState from '../../../user/mobx/apiKeyState';
import Icon from '../../../components/icon/Icon';

import ApiKeyComponent from './ApikeyComponent';
import Spinner from '../../../components/bootstrap/Spinner';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import auth from '../../../shared/mobx/auth-state';
import ConfirmationModal from '../ConfirmationModal';

const ApiKeyCrud: React.FC = () => {
    const [createApiKeyModal, setCreateApiKeyModal] = useState<boolean>(false);
    const [generatedApiKey, setGeneratedApiKey] = useState<string>('');
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [keyId, setKeyId] = useState(0);
    const [isVisible, setVisiblity] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            await apiKeyState.resetState();
            await apiKeyState.refreshKeyList({ noLoader: false });
        };
        if (auth.isUser) {
            fetchData();
        }
    }, []);



    const handleResetApiKey = async (apiKeyId: number) => {
        setKeyId(apiKeyId)
        setConfirmationModal(true)
        // await apiKeyState.resetKey(apiKeyId);
    };

    const doResetApiKey = async () => {
        setConfirmationModal(false)
        await apiKeyState.resetKey(keyId);

    }

    const handleToggleStatus = async (apiKeyId: number) => {
        await apiKeyState.toggleStatus(apiKeyId);
    };
    const handleDelete = async (apiKeyId: number) => {
        await apiKeyState.delete(apiKeyId);
    };


    const handleCreateApiKey = async () => {
        // const data = await apiKeyState.generateKey();
        // setGeneratedApiKey("KKKKKKKKKKKKKKKKKKey999999");
        //  console.log(data);
    };

    const handleKeyCreateModal = () => {
        setGeneratedApiKey("");
        setCreateApiKeyModal(true)
    }


    return (
        <PageWrapper title="API Key Management">
            <ConfirmationModal
                isOpen={confirmationModal} // Use the renamed state variable
                setIsOpen={setConfirmationModal} // Use the updated setter function
                loading={apiKeyState.isLoading}
                title="Confirmation"
                message="Are you sure you want to reset?"
                handleAction={doResetApiKey}
            />
            <Page container='fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='display-6 fw-bold py-2'>API key management</div>
                    </div>

                    {
                        auth.isUser && (
                            <div className='col-12'>

                                <Card>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='Key' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                API Key
                                            </CardTitle>
                                        </CardLabel>
                                        {/* <CardActions>
                                    <Button
                                        color='primary'
                                        onClick={handleKeyCreateModal}>
                                        Create Key
                                    </Button>
                                </CardActions> */}
                                    </CardHeader>
                                    <CardBody className='table-responsive'>
                                        <LoaderWrapper isLoading={apiKeyState.isLoading} data={apiKeyState.keyList}>
                                            <table className='table table-modern'>
                                                <thead>
                                                    <tr>
                                                        <th>Key</th>
                                                        <th>Created At</th>
                                                        <th>Status</th>
                                                        <th className='text-center'>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        apiKeyState.keyList?.map((apiKey) => (
                                                            <tr key={apiKey.id} className='h-20'>
                                                                <td style={{ width: '40%' }}>
                                                                    <ApiKeyComponent isVisible={isVisible} apiKey={apiKey.key} />
                                                                </td>
                                                                <td>{dayjs(apiKey.createdAt).format('MM-DD-YYYY')}</td>
                                                                <td>
                                                                    {
                                                                        apiKey.loading ? <><Spinner size="12px" color="primary" /></>
                                                                            : <Checks
                                                                                id="status"
                                                                                name={String}
                                                                                className="status"
                                                                                type="switch"

                                                                                onChange={() => handleToggleStatus(apiKey.id)}
                                                                                checked={apiKey.status == "Active"}

                                                                            />
                                                                    }

                                                                </td>
                                                                <td className='text-center'>
                                                                    {
                                                                        apiKey.loading ? <><Spinner size="12px" color="primary" /></>
                                                                            : <>
                                                                                <ApiKeyComponent isVisible={isVisible} setVisiblity={setVisiblity} apiKey={apiKey.key} showOnly='actions' />
                                                                                <Button color='danger' icon='Refresh' onClick={() => handleResetApiKey(apiKey.id)}>
                                                                                    Reset
                                                                                </Button></>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            {/* 
                                    { apiKeyState.keyList[0] && <KeyUsage />} */}

                                        </LoaderWrapper>
                                    </CardBody>
                                </Card>

                                {/* Modal component */}
                                <CreateApiKeyModal
                                    isOpen={createApiKeyModal}
                                    setIsOpen={setCreateApiKeyModal}
                                    generatedApiKey={generatedApiKey}
                                    handleCreateApiKey={handleCreateApiKey}
                                />
                            </div>
                        )
                    }

                    <div className="col-12">
                        {(apiKeyState.keyList[0] || auth.isAdmin) && <KeyUsage />}
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default observer(ApiKeyCrud);
