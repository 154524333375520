import ApiKeyCrud from "../pages/presentation/api-key/ApiKeyCrud";
import DashboardPage from "../pages/presentation/dashboard/DashboardPage";
import Packages from "../pages/presentation/packages/Packages";
import UserPdfHistory from "../pages/presentation/users/userPdfHistory";
import UserPackageHistory from "../pages/presentation/packages/userPackageHistory";
import UsersPage from "../pages/presentation/users/users";
import ContactPage from "../pages/presentation/users/contacts";
import UserDetail from "../pages/presentation/users/userDetail";
import SettingsPage from "../pages/presentation/settings/SettingsPage";
import ProfilePage from "../pages/presentation/users/ProfilePage";

interface RouteConfig {
  path: string;
  component: React.ComponentType<any>;
  access: string[];
  icon?:string;
}

export const routes: RouteConfig[] = [
	{ path: '/dashboard', component: DashboardPage, access: ['admin', 'user'] },
	{ path: '/subscriptions', component: Packages, access: ['admin', 'user'] },
	{ path: '/users', component: UsersPage, access: ['admin'] },
	{ path: '/admin-contact-us-list', component: ContactPage, access: ['admin'] },
	{ path: '/users/details/:id', component: UserDetail, access: ['admin'] },
	{ path: '/user-subscription-history', component: UserPackageHistory, access: ['user'] },
	{ path: '/user-pdf-history', component: UserPdfHistory, access: ['user'] },
	{ path: '/api-key-management/:userId?', component: ApiKeyCrud, access: ['user', 'admin'] },
	{path: '/settings', component: SettingsPage, access:['admin']},
	{path: '/my-profile', component: ProfilePage, access:['user','admin']}
  ];