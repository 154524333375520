//@ts-nocheck

import { FC } from "react";
import Page from '../../../layout/Page/Page';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
    SubHeaderLeft,
    SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import { ISubsPlan } from '../../../shared/mobx/subscription-state';

import Icon from '../../../components/icon/Icon';
import Card, {
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import PaddlePay from "../../../components/payment/PaddlePay";
import { Paddle } from '@paddle/paddle-js';
import { formatNumberWithDecimal } from "../../../helpers/helpers";
import auth from "../../../shared/mobx/auth-state";
interface IProps {
    packageData: ISubsPlan;
    paddle: Paddle
}

const currencyMapper = {
    "USD": "$"
};

const pckgInfo = {
    "Basic": {
        icon:'CustomRocketLaunch',
        color:'warning'
    },
    "Professional": {
        icon:'Maps Home Work',
        color:'primary',
        borderColor:'primary',
        borderSize:3, 
        shadow:'lg'
    },
    "Corporate":{
        icon:'CustomFactory',
        borderSize:0, 
        color:'info'
    }
}
const PackageCard: FC<IProps> = ({ packageData, paddle }) => {
    return (
        <Card stretch borderSize={ pckgInfo[packageData.name]?.borderSize } borderColor={ pckgInfo[packageData.name]?.color } >
            <CardBody>
                <div className='row pt-5 g-4 align-items-center'>
                    <div className='col-auto'>
                        <Icon icon={ pckgInfo[packageData.name]?.icon }  size='5x' color={ pckgInfo[packageData.name]?.color } />
                    </div>
                    <div className='col'>
                        <h2>{packageData.name}</h2>
                    </div>
                    <div className='col-12'>
                        <h3 className='display-4 fw-bold'>
                            <span className='display-4 fw-bold'>{currencyMapper[packageData.unit_price.currency_code] ?? packageData.unit_price.currency_code}</span>{formatNumberWithDecimal(packageData.unit_price.amount)}
                            <span className='display-6'>/  {packageData.billing_cycle?.interval ? (packageData.billing_cycle?.interval) : ""}</span>
                        </h3>
                    </div>

                    <div className='col-12'>
                        <p dangerouslySetInnerHTML={{ __html: packageData.description }}>
                            {/* {packageData.description} */}
                        
                        </p>
                    </div>
              
                </div>
            </CardBody>
            <CardFooter className="w-100 text-center">
            {/* <PaddlePay  paddle={paddle} priceId={packageData.id} packagePrice={packageData.unit_price.amount} /> */}
            <PaddlePay 
  paddle={paddle} 
  priceId={packageData.id} 
  packagePrice={packageData.unit_price.amount} 
  {...(auth.currentUser.selected_plan !== null && auth.currentUser.customer_id === null
    ? { selected_plan: true, package_id: auth.currentUser.selected_plan } : {})} 
/>
            </CardFooter>
        </Card>
    )
}

export default PackageCard;