// @ts-nocheck
import { apiClient } from '../base-classes/apiBaseClass';
import { configure, makeAutoObservable } from "mobx";
import notificationState from './notification-state';

export interface IFilters {
  id: string,
}

interface Price {
  amount: string;
  currency_code: string;
}

interface BillingCycle {
  interval: string;
  frequency: number;
}

interface TrialPeriod {
  interval: string;
  frequency: number;
}

interface QuantityRange {
  minimum: number;
  maximum: number;
}

export interface ISubsPlan {
  id: string;
  product_id: string;
  type: string;
  description: string;
  name: string;
  billing_cycle: BillingCycle;
  trial_period: TrialPeriod;
  tax_mode: string;
  unit_price: Price;
  unit_price_overrides: any[];
  custom_data: any;
  status: string;
  quantity: QuantityRange;
  import_meta: any;
  created_at: string;
  updated_at: string;
  // Derived fields
  actualPrice: string;
  discountPrice: string | null;
  trialDays: number;
}

export interface IUserSubscription {
  id: number;
  package_name: string;
  userId: number;
  plan_id: string;
  transaction_id: string;
  subscription_id: string;
  currency_code: string;
  package_price: number;
  billing_cycle: string;
  paid_at: string;
  activationDate: string;
  expiryDate: string;
  canceled_at: string | null;
  createdAt: string; // Consider using Date type if working with Date objects
  updatedAt: string; // Consider using Date type if working with Date objects
  deleted: boolean;
  status: 'active' | 'inactive' | string; // Adjust according to possible statuses
}


class SubscriptionState {
  _planList: ISubsPlan[] = [];
  _userSubscription!: IUserSubscription;
  constructor() {
    configure({ enforceActions: "never" });
    makeAutoObservable(this)
  }

  async filterSubscriptionPlans(filters: IFilters) {
  }
  get UserActiveSubscription(): ISubsPlan {
    return this._userSubscription ?
      this.planList.find(sb => sb.id === this._userSubscription.plan_id)
      : null;
  }
  get hasSubscription(): boolean {
    return !!this._userSubscription?.id;
  }
  get planList(): ISubsPlan[] {
    // if (this._planList.length === 0) {
    //   this.getSubscriptionPlans();
    // }
    return this._planList || [];
  }

  async subscriptionHistory(userId: string, config) {
    try {
      const response = await apiClient.get(`subscriptions-log-list?per_page=${config.per_page || 10}&page=${config.page}`);
      return response.data;
    } catch (e: any) {
      notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
    }
  }

  async adminUserSubscription(userId) {
    try {
      const response = await apiClient.get("admin/admin-user-subscription/" + userId);
      return response.data?.userSubscription || [];
    } catch (e: any) {
      notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
    }
  }
  async userSubscriptionHistory(userId, config) {
    try {
      const response = await apiClient.get(`admin/admin-user-subscription-log/${userId}?per_page=${config.per_page || 10}&page=${config.page}`);
      return response.data || {};
    } catch (e: any) {
      notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
    }
  }


  async getSubscriptionPlans(withActivePlan = false) {
    if (withActivePlan) {
      await this.getActiveSubscriptionPlan();
    }
    this._planList = [];
    try {
      const response = await apiClient.get("subscriptions-list");
      this._planList = response.data;
      return this._planList;
    } catch (e) {
      notificationState.addToasterError("Something went wrong...");
    }
    return [];
  }
  async update(planId) {
    try {
      const response = await apiClient.post("update-subscription",
        { planId, subId: this._userSubscription.subscription_id });
      if (response.status) {
        notificationState.addToasterSuccess(response.message || 'Subscription updated successfully...');
      } else {
        notificationState.addToasterError(response.message || "Something went wrong...");
      }
    } catch (e: any) {
      const error = e.message || "Error..."
      notificationState.addToasterError(error);
    }
    await this.getSubscriptionPlans();
  }

  async cancelUserSubscription(data) {
    try {
      const response = await apiClient.post("admin/user-cancel-subscription", data);
      if (response.status) {
        notificationState.addToasterSuccess(response.message || 'Subscription cancelled successfully...');
      } else {
        notificationState.addToasterError(response.message || "Something went wrong...");
      }
      return response.status;
    } catch (e: any) {
      const error = e.message || "Error..."
      notificationState.addToasterError(error);
    }
  }

  async cancel() {
    try {
      const response = await apiClient.post("cancel-subscription", {
        subId: this._userSubscription.subscription_id,
        plan_id: this._userSubscription.plan_id,
        transaction_id: this._userSubscription.transaction_id
      });
      if (response.status) {
        this._userSubscription = null;
        notificationState.addToasterSuccess(response.message || 'Subscription cancelled successfully...');
      } else {
        notificationState.addToasterError(response.message || "Something went wrong...");
      }
    } catch (e: any) {
      const error = e.message || "Error..."
      notificationState.addToasterError(error);
    }
    await this.getSubscriptionPlans();
  }

  async getActiveSubscriptionPlan() {
    try {
      const response = await apiClient.get("current-package");
      this._userSubscription = response.data;
    } catch (e) {
      // notificationState.addToasterError("Something went wrong...");
    }
  }
}

const subscriptionState = new SubscriptionState();
export default subscriptionState;