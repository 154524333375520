//@ts-nocheck
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Button from '@mui/material/Button';
import Input from '../../../components/bootstrap/forms/Input';
import moment, { Moment } from 'moment';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Icon from '../../../components/icon/Icon';

function DateRangePickerComponent({ setStartDate, setEndDate, startDate, endDate }) {

    const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);


    return (
        <div className='date-range-wrap text-start' style={{}}>
            {/* <Input
                style={{ width: '200px' }}
                className='form-control'
                onClick={() => setFocusedInput('startDate')}
                value={getDisplayValue()}
                readOnly
                
            /> */

            }
            <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                isOutsideRange={() => false} // Allow all dates to be selectable
                showClearDates={true} // Show a clear button on the date picker
            />
            <Tooltips title="Choose your period to adjust the view to display information for a specific period by setting your date range.">
                <Icon color='primary' icon='InfoOutline' style={{ margin: '5px 10px' }} size='2x' />
            </Tooltips>


        </div>
    );
}

export default DateRangePickerComponent;
