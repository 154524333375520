//@ts-nocheck
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import ConfirmationModal from '../ConfirmationModal';
import useDarkMode from '../../../hooks/useDarkMode';
import userState from '../../../shared/mobx/user-state';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import AddUserModal from './AddUserModal';
import ViewContactUsModal from './ViewContactUsModal'; // Import the ViewContactUsModal
import ReplyModal from './ReplyModal';

const ContactPage = () => {
  const { darkModeStatus } = useDarkMode();
  const [users, setUsers] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10); // Default items per page
  const [totalRecords, setTotalRecords] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // State for View Modal
  const [refreshValue, setRefresh] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [user, setUser] = useState<any>({});
  const [selectedContact, setSelectedContact] = useState<any>(null); // Selected contact for viewing
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false); // State for Reply Modal
  const [selectedReplyContact, setSelectedReplyContact] = useState<any>(null); // State for selected contact for reply


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenViewModal = (contact) => {
    setSelectedContact(contact);
    setIsViewModalOpen(true);
  };
  const handleCloseViewModal = () => setIsViewModalOpen(false);
  const handleOpenReplyModal = (contact) => {
    setSelectedReplyContact(contact); // Set the selected contact
    setIsReplyModalOpen(true); // Open the modal
  };
  
  const handleCloseReplyModal = () => {
    setIsReplyModalOpen(false); // Close the modal
  };
  
  const refresher = () => {
    setUser({});
    setRefresh(refreshValue + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const d = await userState.getcontactList({ per_page: perPage, page: currentPage }); // Modify this according to how your function accepts currentPage & perPage
      setUsers(d?.data);
      setTotalRecords(d?.total_records);
      setLoading(false);
    };
    fetchData();
  }, [currentPage, perPage, refreshValue]);

  const handleDelete = async (item) => {
    setUser(item);
    setConfirmationModal(true);
  };

  const deleteAction = async () => {
    setDeleteLoader(true);
    await userState.deleteContact(user.id);
    setUser({});
    setConfirmationModal(false);
    refresher();
    setDeleteLoader(false);
  };

  return (
    <PageWrapper title="ContactUs">
      <Page container='fluid'>
        <div className='row'>
          <div className='col-12'>
            <Card>
              <CardHeader borderSize={1}>
                <CardLabel iconColor='info'>
                  <CardTitle tag='div' className='h5'>
                    Contact Inquiries
                  </CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody className='table-responsive'>
                <LoaderWrapper isLoading={isLoading} data={users}>
                  <>
                    <table className='table table-modern'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Message</th>
                          <th>Received Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item) => (
                          <tr key={item.id}>
                            <td>{item.full_name}</td>
                            <td>{item.email}</td>
                            <td style={{width:'30%'}}>{item.message}</td>
                            <td>{dayjs(item.createdAt).format('MM-DD-YYYY')}</td>
                            <td>
                              <Dropdown>
                                <DropdownToggle hasIcon={false}>
                                  <Button icon='MoreHoriz' aria-label='More options' />
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                  <DropdownItem>
                                    <Button onClick={() => handleOpenViewModal(item)} icon='Visibility'>
                                      View
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button onClick={() => handleDelete(item)} icon='Delete'>
                                      Delete
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
        <Button onClick={() => handleOpenReplyModal(item)} icon="Reply">
          Reply
        </Button>
      </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <PaginationButtons
                      data={users}
                      label='users'
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      totalItems={totalRecords}
                    />
                  </>
                </LoaderWrapper>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
      {isModalOpen ? (
        <AddUserModal refresher={refresher} user={user} isOpen={isModalOpen} onClose={handleCloseModal} />
      ) : null}
      <ConfirmationModal
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        loading={deleteLoader}
        title="Confirmation?"
        message="Are you sure you want to delete this inquiry?"
        handleAction={deleteAction}
      />
      {isViewModalOpen ? (
        <ViewContactUsModal
          isOpen={isViewModalOpen}
          onClose={handleCloseViewModal}
          contactMessages={[selectedContact]} // Pass the selected contact message
        />
      ) : null}
      {isReplyModalOpen && (
  <ReplyModal
    isOpen={isReplyModalOpen}
    onClose={handleCloseReplyModal}
    contact={selectedReplyContact} // Pass the selected contact for reply
  />
)}
    </PageWrapper>
  );
};

export default ContactPage;
