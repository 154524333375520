// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { dynamicLoader } from '../../../helpers/helpers';
import { useLocation } from 'react-router-dom'; // Import useLocation

interface ILoginHeaderProps {
	isNewUser?: boolean;
    isForgotPassword?:boolean,
	isResetPassword?:boolean,
	isVerifyEmail?:boolean,
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, isForgotPassword, isResetPassword, isVerifyEmail  }) => {
	const location = useLocation(); // Get the current route

		useEffect(()=>{
			if (location.pathname === '/') {
				document.body.classList.add('home-page');
			}else{
				document.body.classList.remove('home-page');
			}
			dynamicLoader.removeDynamicElements();
		},[location.pathname])
	  if (isResetPassword) {
        return (
			<>
				<div className='text-center h1 fw-bold mt-4'>Reset Password,</div>
				<div className='text-center h4 text-muted mb-4'>Set new password...</div>
			</>
		);
      }
      if (isForgotPassword) {
        return (
			<>
				<div className='text-center h1 fw-bold mt-4'>Forgot Password,</div>
				<div className='text-center h4 text-muted mb-4'>Enter Email to get started!</div>
			</>
		);
      }
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-4'>Create Account,</div>
				<div className='text-center h4 text-muted mb-4'>Sign up to get started!</div>
			</>
		);
	}
	if (isVerifyEmail) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-4'>Resend Email</div>
				<div className='text-center h4 text-muted mb-4'>Enter Email to verify!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-4'>Welcome,</div>
			<div className='text-center h4 text-muted mb-4'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

export default LoginHeader;