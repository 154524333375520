//@ts-nocheck
import React, { useState } from "react";
import PropTypes from 'prop-types'; // First, import PropTypes
import Icon from '../../../components/icon/Icon';
import notificationState from '../../../shared/mobx/notification-state';
const ApiKeyComponent = ({ apiKey = '', showOnly = '', isVisible = false, setVisiblity = () => { } }) => {

    const handleCopyApiKey = () => {
        navigator.clipboard.writeText(apiKey)
            .then(() => {
                notificationState.addToasterSuccess("Copied...");
            })
            .catch((err) => {
                notificationState.addToasterError("Not supported...");
            });
    };
    return (
        <>

            {
                showOnly === 'actions' ? (
                    <span>
                        <Icon
                            icon={isVisible ? 'VisibilityOff' : 'Visibility'}
                            color="primary"
                            size="2x"
                            className='cursor-pointer mx-2'
                            onClick={() => setVisiblity(!isVisible)}
                        />
                        <Icon
                            icon='ContentCopy'
                            color="primary"
                            size="2x"
                            className='cursor-pointer mx-2'
                            onClick={handleCopyApiKey}
                        />
                    </span>
                ) : (

                    <label htmlFor="" style={{ width: '80%' }} className="font-weight-bold">{isVisible ? apiKey : "*".repeat(apiKey.length)}</label>

                )
            }




        </>
    );
};

// Here, we're defining propTypes for ApiKeyComponent to enforce the type of its props
ApiKeyComponent.propTypes = {
    apiKey: PropTypes.string,
    showOnly: PropTypes.string,
    // This specifies that apiKey prop is required and must be a string
};

export default ApiKeyComponent;
