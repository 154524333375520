

//@ts-nocheck
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
// import SubHeader, {
// 	SubHeaderLeft,
// 	SubHeaderRight,
// 	SubheaderSeparator,
// } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/bootstrap/Badge';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../components/bootstrap/Card';


import userState from '../../../shared/mobx/user-state';
import Button from '../../../components/bootstrap/Button';

import Avatar, { AvatarGroup } from '../../../components/Avatar';
import USERS from '../../../common/data/userDummyData';

import Progress from '../../../components/bootstrap/Progress';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import BarChart from './BarChart';
import DateRange from './DateRange';


import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {

    const [data, setData] = useState<any>({});
    const [cardData, setCardData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState({});
    const darkModeStatus = false;
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let dates = {}
            if (startDate && endDate) {
                dates['startDate'] = moment(startDate).format('YYYY-MM-DD')
                dates['endDate'] = moment(endDate).format('YYYY-MM-DD')
            }
            const data_ = await userState.getAdminStats(dates);
            setLoading(false);
            if (data_) {
                const tempD = [
                    // User-related statistics
                    {
                        name: "User Stats",
                        description: "Active Users",
                        count: data_.totalActiceUser,
                        class: "bg-l25-primary bg-l10-primary-hover",
                        to: '/users',
                        icon: "Person" // Icon for active users
                    },
                    {
                        name: "User Stats",
                        description: "InActive Users",
                        count: data_.totalInActiceUser,
                        class: "bg-l25-secondary bg-l10-secondary-hover",
                        icon: "PersonOff" // Icon for inactive users
                    },
                    {
                        name: "Subscription Details",
                        description: "Users With Subscription",
                        count: data_.totalSubscriptionPackagesUser,
                        class: "bg-l25-success bg-l10-success-hover",
                        icon: "CardMembership" // Icon for users with subscriptions
                    },
                    {
                        name: "User Stats",
                        description: "Total Users",
                        count: data_.totalUser,
                        class: "bg-l25-warning bg-l10-warning-hover",
                        to: '/users',
                        icon: "People" // Icon for total users
                    },

                    // Subscription details
                    {
                        name: "Subscription Details",
                        description: "Subscription to expire in 7 Days",
                        count: data_.sevenDaysExpire,
                        class: "bg-l25-primary bg-l10-primary-hover",
                        icon: "HourglassBottom" // Icon indicating expiration
                    },

                    // PDF generation statistics
                    {
                        name: "PDF Generation Stats",
                        description: "Free Pdf Generated",
                        count: data_.totalpdffree,
                        class: "bg-l25-secondary bg-l10-secondary-hover",
                        icon: "PictureAsPdf" // Icon for PDF generation
                    },
                    {
                        name: "PDF Generation Stats",
                        description: "Paid Pdf Generated",
                        count: data_.totalPDFgenerateSubscription,
                        class: "bg-l25-success bg-l10-success-hover",
                        icon: "MonetizationOn" // Icon for paid PDF generation
                    },

                    // Total PDF generation stats
                    {
                        name: "PDF Generation Stats",
                        description: "Total Pdf Generated",
                        count: data_.totalPDFgenerateSubscription + data_.totalpdffree,
                        class: "bg-l25-warning bg-l10-warning-hover",
                        icon: "Summarize" // Icon for total PDF generated
                    },
                ];

                setCardData(tempD);
                setChartData(
                    {
                        userRegisterWeekDaysWise: {
                            categories: Object.keys(data_.userRegisterWeekDaysWise ?? {}) || [],
                            data: Object.values(data_.userRegisterWeekDaysWise ?? {}) || [],
                        },
                        totalSubscriptionDaywise: {
                            categories: Object.keys(data_.totalSubscriptionDaywise ?? {}) || [],
                            data: Object.values(data_.totalSubscriptionDaywise ?? {}) || [],
                        },
                        pdfGeneratedDaysWise: {
                            categories: Object.keys(data_.pdfGeneratedDaysWise ?? {}) || [],
                            data: Object.values(data_.pdfGeneratedDaysWise ?? {}) || [],
                        }
                    }
                );

            }

        }
        fetchData();
    }, [endDate]);
    return (
        <>
            <div className='row mt-3'>
                <div className="col-sm-12">
                    <DateRange setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />

                    <Card
                        className={classNames('transition-base rounded-2 mb-0 text-dark')}
                        stretch
                        shadow='sm'>
                        {/* <CardHeader className='bg-transparent'>
                            <CardLabel>
                                <CardTitle tag='div' className='h5'>
                                    Stats
                                </CardTitle>
                            </CardLabel>
                        </CardHeader> */}
                        <CardBody>
                            <LoaderWrapper isLoading={loading} data={cardData}>
                                <div className="row">
                                    {cardData?.map(d => (
                                        <div className="col-sm-3">
                                            <Card stretch
                                                onClick={() => d.to ? navigate(d.to) : null}
                                                className={classNames('transition-base rounded-2 mb-4 text-dark', {
                                                    [d.class]: true
                                                })}

                                                style={{ cursor: d.to ? 'pointer' : '' }}

                                                shadow='sm'>
                                                <CardHeader className='bg-transparent'>
                                                    <CardLabel>
                                                        <CardTitle tag='div' className='h5'>
                                                            {d.description}
                                                        </CardTitle>
                                                    </CardLabel>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className='d-flex align-items-center pb-3'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon={d.icon || 'ConfirmationNumber'} size='4x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold fs-3 mb-0'>
                                                                {d.count}
                                                                {/* <PercentComparison
                        valueOne={sales.coupons.price}
                        valueTwo={sales.coupons.compare}
                    /> */}
                                                            </div>
                                                            <div
                                                                className={classNames({
                                                                    'text-muted': !darkModeStatus,
                                                                    'text-light': darkModeStatus,
                                                                })}>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    ))

                                    }
                                </div>
                            </LoaderWrapper>
                        </CardBody>
                    </Card>
                </div>
            </div>
            {!loading && (
                <div className="row mt-3 mb-3">
                    <div className="col-sm-12">
                        <Card
                            className={classNames('transition-base rounded-2 mb-0 text-dark admin-dashboard')}
                            stretch
                            shadow='sm'>
                            <CardHeader className='bg-transparent'>
                                <CardLabel>
                                    <CardTitle tag='div' className='h5'>
                                        User & Subscription Chart
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <BarChart data={chartData.userRegisterWeekDaysWise?.data} categories={chartData.userRegisterWeekDaysWise?.categories} name="Users Registered" title="Number of Users Registered in the Week" />
                                    </div>

                                    <div className="col-sm-6">
                                        <BarChart
                                            data={chartData.totalSubscriptionDaywise?.data}
                                            categories={chartData.totalSubscriptionDaywise?.categories}
                                            name="Subscriptions Sold"
                                            title="Number of Subscriptions Sold in the Week" />
                                    </div>

                                    {/* <div className="col-sm-6">
                                        <BarChart
                                            data={chartData.pdfGeneratedDaysWise?.data}
                                            categories={chartData.pdfGeneratedDaysWise?.categories}
                                            name="PDFs Generated"
                                            title="Number of PDFs Generated in the Week" />
                                    </div> */}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminDashboard;
