// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './styles.css'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import NotificationToast from './components/notification/NotificationToast';
import { dynamicLoader } from '../helpers/helpers';
import CosmicOrbitLoader from '../components/CosmicOrbitLoader';
import PrivacyPolicy from './components/sections/PrivacyPolicy';

const PrivacyPolicyPage = () => {
  const [showPreloader, setShowPreloader] = useState(true);

  document.title = "Web2PDF";
  useEffect(() => {
    dynamicLoader.addScripts();
    setTimeout(() => {
      setShowPreloader(false);
    }, 500);
  });
  if (showPreloader) {
    return <CosmicOrbitLoader />;
  }
  return (
    <>
      <NotificationToast />
      <Navbar />
      <PrivacyPolicy />
      <Footer />
    </>
  );
}
export default PrivacyPolicyPage;
