//@ts-nocheck
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import classNames from 'classnames';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';

import { DownloadFile } from '../../../shared/utils';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';

import pdfServiceStore from '../../../user/mobx/pdfState';
import { toTitleCase } from '../../../helpers/helpers';
import PaginationButtons from '../../../components/PaginationButtons';
import moment, { Moment } from 'moment';
const EVENT_STATUS = {
    'pending':"warning",
    'complete':"success",
    'failed':"danger"
};

interface UserHistoryData {
    id: number;
    ip: string;
    sessionId: string;
    pdfPath: string | null;
    userId: number;
    targetUrl: string;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    source: string;
    status: string;
}
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
}

const UserPdfHistory: React.FC = ({startDate, endDate}) => {

    const [UserpdfHistory, setUserPdfHistory] = useState<UserHistoryData[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewPath, setPreviewPath] = useState("");

    const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);


    const openPreviewModal = (item) => {
        setPreviewPath(item.pdfPath ?? "");
        setPreviewModalOpen(true);
    };

    const closePreviewModal = () => {
        setPreviewPath("");
        setPreviewModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
                  let dates = {}
            if (startDate && endDate) {
                dates['startDate'] = moment(startDate).format('YYYY-MM-DD')
                dates['endDate']  = moment(endDate).format('YYYY-MM-DD')
             }
			const {data, total_records} = await pdfServiceStore.userPdfHistory({page:currentPage, per_page: perPage}, dates);
			setTotalItems(total_records)
            setUserPdfHistory(data);
            setLoading(false);
        };
        fetchData();
    }, [currentPage,endDate]);

    return (
        <>
            <Modal
                isOpen={previewModalOpen}
                onClose={closePreviewModal}
                title="Preview PDF"
                setIsOpen={setPreviewModalOpen}
                size="xl"
                buttonText="Close PDF">
                <ModalHeader setIsOpen={setPreviewModalOpen}>
                    <ModalTitle Id="preview_pdf">Preview</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <iframe title="PDF Preview" src={previewPath} width="100%" height="500px" />
                </ModalBody>
            </Modal>
            <div className='row'>
                <div className='col-12 mt-3'>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    PDF History
                                </CardTitle>
                            </CardLabel>
                            {/* Export button can be added here if needed */}
                        </CardHeader>
                        <LoaderWrapper isLoading={isLoading} data={UserpdfHistory}>
                            <CardBody className='table-responsive' style={{overflow:'auto'}}>
                                <table className='table table-modern dashboard-table' >
                                    <thead>
                                        <tr  >
                                            <th role='col'>Target URL</th>
                                            <th role='col'>Page</th>
                                            <th role='col'>Source</th>
                                            
                                            {/* <th>PDF Path</th> */}
                                            <th role='col'>Status</th>
                                            <th role='col'>Completed Date</th>
                                            <th role='col' className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UserpdfHistory?.slice(0, 10).map((item) => (
                                            <tr key={item.id}>
                                                <td role='col'>{truncateText(item.targetUrl, 40)}</td>
                                                <td role='col'> {item.type == 'current_page' ? 'Single' : 'Multiple' } </td>
                                                <td role='col'>{toTitleCase(item.source)}</td>
                                                {/* <td>{item.pdfPath ? item.pdfPath : 'N/A'}</td> */}




                                                <td role='col'>
                                                <Button
                                                        isLink
                                                        isDisable={true}
													color={EVENT_STATUS[item.status]??'warning'}
													icon='Circle'
													className='text-nowrap'>
													{toTitleCase(item.status === "complete" ? "Completed" : item.status)}
												</Button>
                                                </td>
                                                <td role='col'>{item.status === "complete" ? dayjs(item.updatedAt).format('MM-DD-YYYY') : <>--</>}</td>
                                                <td role='col' className='text-center'>

                                                    {item.status === "complete" && item.pdfPath && (
                                                        <Dropdown>
                                                            <DropdownToggle hasIcon={false}>
                                                                <Button
                                                                    icon='MoreHoriz'
                                                                    aria-label='More options'
                                                                />
                                                            </DropdownToggle>
                                                            <DropdownMenu isAlignmentEnd>


                                                                <DropdownItem>
                                                                    <Button onClick={() => openPreviewModal(item)} icon='Preview'>
                                                                        Preview
                                                                    </Button>
                                                                </DropdownItem>
                                                                <DropdownItem>
                                                                    <Button onClick={() => DownloadFile(item.pdfPath, item.sessionId)} icon='Download'>
                                                                        Download
                                                                    </Button>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>

                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
								label='items'
								setCurrentPage={setCurrentPage}
								totalItems={totalItems}
								setPerPage={setPerPage}
								currentPage={currentPage}
								/>
                        </LoaderWrapper>
                    </Card>

                </div>
            </div>
        </>
        // <PageWrapper title="PDF History">
        //     <Page container='fluid'>
        //         <Modal
        //             isOpen={previewModalOpen}
        //             onClose={closePreviewModal}
        //             title="Preview PDF"
        //             setIsOpen={setPreviewModalOpen}
        //             size="xl"
        //             buttonText="Close PDF">
        //             <ModalHeader setIsOpen={setPreviewModalOpen}>
        //                 <ModalTitle Id="preview_pdf">Preview</ModalTitle>
        //             </ModalHeader>
        //             <ModalBody>
        //                 <iframe title="PDF Preview" src={previewPath} width="100%" height="500px" />
        //             </ModalBody>
        //         </Modal>
        //         <div className='row'>
        //             <div className='col-12'>
        //                 <div className='display-6 fw-bold py-2'>Pdf History</div>
        //             </div>
        //             <div className='col-12'>
        //                 <Card>
        //                     <CardHeader borderSize={1}>
        //                         <CardLabel iconColor='info'>
        //                             <CardTitle tag='div' className='h5'>
        //                                 PDF History
        //                             </CardTitle>
        //                         </CardLabel>
        //                         {/* Export button can be added here if needed */}
        //                     </CardHeader>
        //                     <LoaderWrapper isLoading={isLoading} data={UserpdfHistory}>
        //                         <CardBody className='table-responsive'>
        //                             <table className='table table-modern'>
        //                                 <thead>
        //                                     <tr  >
        //                                         <th>Target URL</th>
        //                                         <th>Source</th>
        //                                         {/* <th>PDF Path</th> */}
        //                                         <th>Status</th>
        //                                         <th>Completed Date</th>
        //                                         <th className='text-center'>Action</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {UserpdfHistory?.map((item) => (
        //                                         <tr key={item.id}>
        //                                             <td>{item.targetUrl}</td>
        //                                             <td>{toTitleCase(item.source)}</td>
        //                                             {/* <td>{item.pdfPath ? item.pdfPath : 'N/A'}</td> */}

        //                                             <td>{toTitleCase(item.status === "complete" ? "Completed" : item.status)}</td>
        //                                             <td>{item.status === "complete" ? dayjs(item.updatedAt).format('MM-DD-YYYY') : <>--</>}</td>
        //                                             <td className='text-center'>

        //                                                 {item.status === "complete" && item.pdfPath && (
        //                                                     <Dropdown>
        //                                                         <DropdownToggle hasIcon={false}>
        //                                                             <Button
        //                                                                 icon='MoreHoriz'
        //                                                                 aria-label='More options'
        //                                                             />
        //                                                         </DropdownToggle>
        //                                                         <DropdownMenu isAlignmentEnd>


        //                                                             <DropdownItem>
        //                                                                 <Button onClick={() => openPreviewModal(item)} icon='Preview'>
        //                                                                     Preview
        //                                                                 </Button>
        //                                                             </DropdownItem>
        //                                                             <DropdownItem>
        //                                                                 <Button onClick={() => DownloadFile(item.pdfPath, item.sessionId)} icon='Download'>
        //                                                                     Download
        //                                                                 </Button>
        //                                                             </DropdownItem>
        //                                                         </DropdownMenu>
        //                                                     </Dropdown>

        //                                                 )}
        //                                             </td>
        //                                         </tr>
        //                                     ))}
        //                                 </tbody>
        //                             </table>
        //                         </CardBody>
        //                     </LoaderWrapper>
        //                 </Card>
        //             </div>
        //         </div>
        //     </Page>
        // </PageWrapper >
    );
};

export default UserPdfHistory;
