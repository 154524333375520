//@ts-nocheck
// src/components/ReplyModal.tsx
import React, { useState } from 'react';
import Modal from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
  CardSubTitle,
} from '../../../components/bootstrap/Card';

const ReplyModal = ({ isOpen, onClose, contact }) => {
  const [replyMessage, setReplyMessage] = useState(''); // State to store reply message

  const handleSendReply = async () => {
    try {
      // Logic for sending reply (e.g., API call)
      console.log(`Sending reply to ${contact.email}: ${replyMessage}`);

      const status = await userState.contactUsReply(contact.email, contact.full_name, replyMessage);

      // You might want to handle the status response here (e.g., show a success or error message)
      if (status.success) {
        console.log('Reply sent successfully');
      } else {
        console.error('Failed to send reply');
      }

      onClose(); // Close modal after sending reply
    } catch (error) {
      console.error('Error sending reply:', error);
      // Optionally, you can also handle any errors that might occur during the API call
    }
  };


  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose}
      titleId="Reply to Contact Message"
      isStaticBackdrop={true}
      isCentered={true}
      isAnimation={true}
      title="Reply to Contact Message"
      onClose={onClose}
      size={"lg"} // Larger size for modal
    >
      <>
        <CardHeader>
          <CardLabel icon="Reply" iconColor="info">
            <CardTitle tag="div" className="h5">
              Reply to Contact Message
            </CardTitle>
            <CardSubTitle tag="div" className="h6">
              Write and send a reply to the selected message
            </CardSubTitle>
          </CardLabel>
        </CardHeader>
        <CardBody >
          {/* Section 1: Display Email */}
          <div className="mb-4">
            <p style={{ color: 'black' }}>
              <strong>Email:</strong> {contact.email}
            </p>
          </div>

          {/* Section 2: Reply Textbox */}
          <div >
            <label>
              <strong>Reply Message:</strong>
            </label>
            <textarea
              className="form-control"
              value={replyMessage}
              onChange={(e) => setReplyMessage(e.target.value)} // Update reply message
              rows={5}
              placeholder="Write your reply here..."
              style={{
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                minHeight: '100px',
                whiteSpace: 'pre-wrap', // Preserve newlines and spaces
                wordWrap: 'break-word', // Allow word wrapping
                backgroundColor: '#f9f9f9', // Light background for message box
                maxHeight: '300px', // Maximum height for reply box
                overflowY: 'auto', // Scrollable if content exceeds max height
              }}
            />
          </div>
        </CardBody>

        <CardActions className="text-end mb-3">
          <Button
            type="button"
            color="primary"
            style={{ padding: '10px' }}
            onClick={handleSendReply} // Call function to send reply
          >
            Send Reply
          </Button>
          <Button
            type="button"
            color="primary"
            style={{
              background: '#78f078', borderColor: '#78f078', color: 'black',
              width: '100px',
              padding: '10px'
            }}

            onClick={() => onClose(false)}
            className="ms-2 me-4 "
          >
            Close
          </Button>
        </CardActions>
      </>
    </Modal>
  );
};

export default ReplyModal;
