import React, { useState, useRef, useEffect } from 'react';
import logoImage from '../images/logo.svg'; // Update this path as needed
import auth from '../../shared/mobx/auth-state';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

const dropDownNavs = [
  { text: "Dashboard", path: "/dashboard", access: ["user", "admin"] },
  { text: "My Profile", path: "/my-profile", access: ["user", "admin"] },
  { text: "Subscription Details", path: "/subscriptions", access: ["user"] },
  { text: "Subscription History", path: "/user-subscription-history", access: ["user"] },
  { text: "Logout", path: "/auth/login?preaction=logout", access: ["user", "admin"] },
];

interface NavbarProps {
  isIndex: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isIndex }) => {
  const [isIconOpen, setIconOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !(dropdownRef.current as HTMLElement).contains(event.target as Node)) {
      setIconOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isIconOpen]);

  const [activeHash, setActiveHash] = useState(window.location.hash || '#home');

  useEffect(() => {
    const handleHashChange = () => {
      setActiveHash(window.location.hash || '#home'); // Default to #home if no hash
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleNavigation = (path: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(path);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop >= 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <span id="up" />
      <header className={`header webtopdf-wrap ${isScrolled ? 'scrolled' : ''}`}>
        <div className="container" >
          <div className="row justify-content-center align-items-center">
            <div className="col-md-3 col-sm-12 col-12">
              <div className="logo">
                <a href="/">
                  <img src={logoImage} className="img-responsive" alt="Logo" />
                </a>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 col-12 mobile-wraper">
              <nav className="navbar navbar-expand-lg justify-content-center">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#home' ? 'active' : ''}`} aria-current="page" href={isIndex ? "#home" : "/"}>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#benefit' ? 'active' : ''}`} href={isIndex ? "#benefit" : "/#benefit"}>
                        Benefits
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#reviews' ? 'active' : ''}`} href={isIndex ? "#reviews" : "/#"}>
                        Reviews
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#pricing' ? 'active' : ''}`} href={isIndex ? "#pricing" : "/#"}>
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#faq' ? 'active' : ''}`} href={isIndex ? "#faq" : "/#faq"}>
                        FAQs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeHash === '#contact' ? 'active' : ''}`} href={isIndex ? "#contact" : "/#contact"}>
                        Contact
                      </a>
                    </li>
                    <ul className="login-menu">
                      {dropDownNavs.map(
                        (item) =>
                          item.access.includes(auth.activeRole) && (
                            <li key={item.text} className="nav-items">
                              <a href={item.path} className="nav-links" onClick={handleNavigation(item.path)}>
                                {item.text}
                              </a>
                            </li>
                          )
                      )}
                    </ul>
                    <div className="sign-btn mobile-show">
                      {auth.isLoggedIn() ? (
                        <button
                          ref={dropdownRef}
                          onClick={() => setIconOpen(!isIconOpen)}
                          className={`circle-button ${isIconOpen ? 'toggleUserIcon' : ''}`}
                          style={{ backgroundColor: auth.currentUser?.profileImage ? 'transparent' : undefined }}
                        >
                          {auth.currentUser?.profileImage ? (
                            <img
                              width="40"
                              height="40"
                              style={{ width: '40px', height: '40px', display: 'block', borderRadius: 'inherit' }}
                              src={auth.currentUser?.profileImage}
                              alt="Profile"
                            />
                          ) : (
                            <i className="fas fa-user user-icon"></i>
                          )}
                          {isIconOpen && (
                            <ul className="dropdown-menus">
                              {dropDownNavs.map(
                                (item) =>
                                  item.access.includes(auth.activeRole) && (
                                    <li key={item.text} className="dropdown-item">
                                      <a href={item.path} onClick={handleNavigation(item.path)}>
                                        {item.text}
                                      </a>
                                    </li>
                                  )
                              )}
                            </ul>
                          )}
                        </button>
                      ) : (
                        <Link className="nav-link" to="/auth/login">
                          Sign In
                        </Link>
                      )}
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-md-2 col-sm-0 col-0">
              <div className="sign-btn">
                {auth.isLoggedIn() ? (
                  <button
                    ref={dropdownRef}
                    onClick={() => setIconOpen(!isIconOpen)}
                    className={`circle-button ${isIconOpen ? 'toggleUserIcon' : ''}`}
                    style={{ backgroundColor: auth.currentUser?.profileImage ? 'transparent' : undefined }}
                  >
                    {auth.currentUser?.profileImage ? (
                      <img
                        width="40"
                        height="40"
                        style={{ width: '40px', height: '40px', display: 'block', borderRadius: 'inherit' }}
                        src={auth.currentUser?.profileImage}
                        alt="Profile"
                      />
                    ) : (
                      <i className="fas fa-user user-icon"></i>
                    )}
                    {isIconOpen && (
                      <ul className="dropdown-menus">
                        {dropDownNavs.map(
                          (item) =>
                            item.access.includes(auth.activeRole) && (
                              <li key={item.text} className="dropdown-item">
                                <a href={item.path} onClick={handleNavigation(item.path)}>
                                  {item.text}
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  </button>
                ) : (
                  <Link className="nav-link" to="/auth/login">
                    Sign In
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>


  );
};

export default observer(Navbar);
